import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "fileInput",
    "docselect"
  ];

  upload(event) {
    this.uploadFile(event.target.files[0]);
  }

  async uploadFile(file) {
    if (!file) {
      console.error("No file selected for upload");
      return;
    }

    const url = $(this.fileInputTarget).data("url");
    const clientId = $(this.fileInputTarget).data("client-id");
    const formData = new FormData();
    formData.append("document[file]", file);
    formData.append("document[category]", "other");
    formData.append("document[visible_for_client]", true);
    formData.append("document[client_id]", clientId);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();

        this.addOptionToSelect2(data.id, file.name);
      } else {
        console.error("File upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("File upload error:", error);
    }
  }

  addOptionToSelect2(id, name) {
    const newDocOption = new Option(name, id, true, true);

    if ($(this.docselectTarget).length > 0) {
      $(this.docselectTarget).append(newDocOption).trigger("change");
    }
  }
}
